import { createContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LeaderboardService } from "./api/leaderBoard";
import { LeaderboardPage } from "./components/LeaderboardPage";
import { LabelNotFoundError } from "./components/LabelNotFoundError";
import ReactGA from "react-ga4";

const servicesInit = {
  leaderboardService: new LeaderboardService(),
};
export const services = createContext<{
  leaderboardService: LeaderboardService;
}>(servicesInit);
export const App = () => {
  const queryClient = new QueryClient();
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID ?? '');
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <services.Provider value={servicesInit}>
          <ReactQueryDevtools initialIsOpen={true} />
          <Routes>
            <Route
              path={"/:label"}
              element={
                <LeaderboardPage/>
              }
            />
            <Route path="*" element={<LabelNotFoundError></LabelNotFoundError>} />
          </Routes>
        </services.Provider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};
