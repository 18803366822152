import styles from '../Titles/styles.module.css';

interface titleProps {
  title: string
  subtitle?: string
}

export function Titles(props: titleProps) {
  const {
    title,
    subtitle
  } = props

  return (
    <div className={styles.titles} >
      <div>
        <p className={styles.title}>{title}</p>
      </div>
      {props.subtitle &&
        <div>
          <p className={styles.subtitle}>{subtitle}</p>
        </div>}
    </div>
  );
}