import { ScoreMarkEnum } from '../../api/enums/leaderboard-enums';
import { BPLeaderboardItem } from '../../api/interfaces/BPleaderboard-item.interface';
import { formatResult } from '../../utils/helpers/time';
import styles from '../ScoreResult/styles.module.css'

interface playerNameProps {
  marks: ScoreMarkEnum[]
  item?: BPLeaderboardItem | any
  emptyString?: string
}

export function ScoreResult(props: playerNameProps) {
  const {
    marks,
    item,
    emptyString
  } = props

  function extractMarkValue(mark: ScoreMarkEnum | string) {
    let stringValue;
    switch (mark) {
      case ScoreMarkEnum.DURATION:
      case ScoreMarkEnum.HITS:
      case ScoreMarkEnum.REPS:
      case ScoreMarkEnum.WINS:
      case ScoreMarkEnum.STRIKES:
        stringValue = item?.[mark]
        break;
      case ScoreMarkEnum.REP_TIME:
        stringValue = item?.avg_rep_time
        break;
      case ScoreMarkEnum.TIMEOUT_MISSES:
        stringValue = item?.timeout_misses
        break;
      case ScoreMarkEnum.REACTION_TIME:
        stringValue = item?.avg_reaction_times ?? '-'
        break;
      case ScoreMarkEnum.TOTAL_REACTION_TIMES:
        stringValue = item?.total_reaction_times
        break;
      case ScoreMarkEnum.LEVEL_SHUTTLE:
        stringValue = item?.level_shuttle
        break;
      case 'timed_playes':
        stringValue = item?.times_played
        break;
      case 'empty_row':
        stringValue = ' '
        break;
    }

    if (!stringValue) {
      stringValue = emptyString ?? '0'
    } else {
      stringValue = formatResult(mark, stringValue)
    }
    return stringValue
  }

  return (
    <div className={styles.stats}>
      {marks.map((mark) => (
        <div className={styles.div}>
          <b className={styles.time}>{extractMarkValue(mark)}</b>
        </div>
      ))}
    </div>
  );
}
