import { useQuery } from "react-query";
import { useParams } from "react-router";
import { HasLabel } from "../../api/interfaces/has-label.interface";
import { QueryKeys } from "../../enums";
import { useAppServices } from "../../hooks/useAppServices";
import { Header } from "../Header";
import { LeaderBoard } from "../LeaderBoard";
import { useEffect, useState } from "react";
import { SmallScreenError } from "../SmallScreenError";
import { Loading } from "../Loading";
import { LabelNotFoundError } from "../LabelNotFoundError";
import ReactGA from "react-ga4";

export function LeaderboardPage() {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const params = useParams<HasLabel>();
  const { leaderboardService } = useAppServices();
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", label: params.label, action: "board_open" });
    return;
  }, [params.label]);

  useEffect(() => {
    const sendCloseEvent = () => {
      ReactGA.send({ hitType: "pageview", label: params.label, action: "board_close" });
      return ''
    };
    window.addEventListener('beforeunload', sendCloseEvent);
    return () => {
      window.removeEventListener('beforeunload', sendCloseEvent);
    };
  }, [params.label]);

  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.Leaderboard,
    queryFn: () => {
      ReactGA.send({ hitType: "pageview", label: params.label, action: "screen_view" });
      return leaderboardService.getLeaderBoard(params.label ?? "")
    },
    refetchInterval: 30000,
  });
  const handleWindowResize = () => {
    setWindowSize(window.innerWidth);
  };

  const handleVisibilityChange = () => {
    ReactGA.send({ hitType: "pageview", label: params.label, action: "background_view", is_hidden: document.hidden });
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('orientationchange', handleWindowResize);
    return () => {
      window.removeEventListener('orientationchange', handleWindowResize);
    };
  }, []);

  if (windowSize < 1024) {
    return <SmallScreenError />
  }

  if (isLoading) {
    return <Loading />;
  }

  if (data?.status.code === 404) {
    return <LabelNotFoundError />
  }

  return (
    <div>
      <Header {...data}></Header>
      <LeaderBoard {...data}></LeaderBoard>
    </div>
  );
}