import error from '../../assets/svgs/error.svg'
import { Header } from '../Header'
import leaderboardStyles from '../LeaderBoard/styles.module.css'
import styles from './styles.module.css'


export function LabelNotFoundError() {
  const LabelNotFoundHeader = {
    payload: {
      title: ""
    },
    status: {
      code: 200
    }
  }
  return (
    <>
      <Header {...LabelNotFoundHeader}></Header>
      <div className={leaderboardStyles.leaderboards}>
        <div className={styles.frameWrapper}>
          <img className={styles.errors} alt="" src={error} />
            <i className={styles.mainText}>No blazing lights here...</i>
            <div className={styles.text}>Please check the leaderboard address has been entered correctly, or contact support for more help.</div>
        </div>
      </div>
    </>
  );
}
