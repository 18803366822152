
import { Logos } from "../Logos";
import { Titles } from "../Titles";
import styles from '../Header/styles.module.css';
import { Titel } from "../Titles/Titles.model";


export function Header(props: any) {
  let {
    payload,
    status
  } = props

  if (status.code > 400) {
    payload = new Titel(`${status.code === 425 ? 'My' : 'BlazePod'} Leaderboard`)
  }
  return (
    <header className={styles.header}>
      <Titles {...payload}/> 
      <Logos {...payload}></Logos>
    </header>
  );
}
