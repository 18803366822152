import { LeaderboardPayload } from "./interfaces/leaderboard-payload.interface";
import { RootObject } from "./interfaces/root-object.interface";
import { BaseApi } from "./baseApi.service";
import { BPLeaderboardPayload } from "./interfaces/BPleaderboard-payload.interface";

export class LeaderboardService extends BaseApi {
  async getLegacyLeaderBoard(
    label: string,
    options?: object
  ): Promise<RootObject<LeaderboardPayload>> {
    const result = await this.client.get(`/leaderboard/${label}`);
    return result.data;
  }

  async getLeaderBoard(
    label: string,
    options?: object
  ): Promise<RootObject<BPLeaderboardPayload>> {
    const result = await this.client.get(`/leaderboard/${label}`);
    return result.data;
  }
}
