export enum ScoreMarkEnum {
    WINS = 'wins',
    HITS = 'hits',
    DURATION = 'duration',
    REACTION_TIME = 'avgReactionTimes',
    LEVEL_SHUTTLE = 'cues',
    REP_TIME = 'avgRepTime',
    REPS = 'reps',
    TIMEOUT_MISSES = 'timeoutMisses',
    STRIKES = 'strikes',
    TOTAL_REACTION_TIMES = 'totalReactionTimes'
}
  