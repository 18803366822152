import { ScoreMarkEnum } from '../../api/enums/leaderboard-enums';
import { BPLeaderboardItem } from '../../api/interfaces/BPleaderboard-item.interface';
import thropy from '../../assets/svgs/vector-icon.svg'
import styles from '../TableData/styles.module.css'
import { PlayerName } from '../PlayerName';
import { ScoreResult } from '../ScoreResult';
import { useMemo } from 'react';

interface tableDataProps {
  size: number,
  items: BPLeaderboardItem[],
  marks: ScoreMarkEnum[]
}

export function TableData(props: tableDataProps) {
  const {
    size,
    items,
    marks
  } = props

  const emptyRowCount = useMemo(() => {
    return size - items.length
  }, [items.length, size])

  return (
    <div className={styles.table}>
        {items.map((item, index) => {
          if(index === 0) {
            return (
              <div className={styles.leaderboards1}>
                <div className={styles.leaderboardFirstRow}>
                  <div className={styles.icontrophy16px}>
                    <img className={styles.vectorIcon1} alt="" src={thropy} />
                  </div>
                <div className={styles.firstRow}>
                  <PlayerName name={item.name}/>
                  <ScoreResult marks={marks} item={item}/>
                </div>
              </div>
            </div>
            )} else { return (
              <div className={styles.leaderboards1}>
                <div className={styles.leaderboardGeneralRow}>
                <div className={styles.rank}>{index+1}</div>
                <div className={styles.generalRow}>
                  <PlayerName name={item.name}/>
                  <ScoreResult marks={marks} item={item}/>
                </div>
                </div>
              </div>
            )
          }
        })}
        {
          emptyRowCount > 0 &&
            [...Array(emptyRowCount)].map((irr, index) => {
            const rank = index+1+items.length
            return (
              <div className={styles.leaderboardsEmpty}>
                <div className={styles.leaderboardGeneralRow}>
                <div className={styles.rank}>{rank}</div>
                <div className={styles.generalRow}>
                  <PlayerName name={''}/>
                  <ScoreResult marks={marks} emptyString='-'/>
                </div>
                </div>
              </div>
            )}
            )}
    </div>);
  }