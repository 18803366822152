import { useContext, useMemo } from "react";
import { LeaderboardService } from "../api/leaderBoard";
import { services } from "../App";
interface UseServices {
  leaderboardService: LeaderboardService;
}
export function useAppServices(): UseServices {
  const servicesContext = useContext(services);

  return useMemo(() => servicesContext, [servicesContext]);
}
