import { useMemo } from 'react'
import styles from '../PlayerName/styles.module.css'
import { handelLongText, isEmptyString } from '../../utils/helpers/strings'

interface playerNameProps {
  name: string
  emptyString?: string
}

export function PlayerName(props: playerNameProps) {
  const {
    name,
    emptyString
  } = props
  const limit = 20;

  const displayName = useMemo(() => {
    if (isEmptyString(name)) {
      return emptyString ?? 'N/A'
    } else {
      return handelLongText(name, limit)
    }
  }, [emptyString, name])

  return (
    <div className={styles.playerName}>
        <div className={styles.name}>{displayName}</div>
    </div>
    );
}
