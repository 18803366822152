/**
 *
 * @param _title any word or name
 * @returns the title in Title Case.
 */
export function getTitleCase(_title: string) {
  const cased_title = _title
    .toLowerCase()
    .split('')
    .map((item, index, array) => {
      if (index === 0) return item.toUpperCase();
      if (array[index - 1] === ' ') return item.toUpperCase();
      return item;
    })
    .join('');
  return cased_title;
}
export function getTitleFromCamelCase(_camelCased: string) {
  const cased_title = _camelCased
    .split('')
    .map((item, index) => {
      let modified = item;
      if (index === 0) {
        modified = item.toUpperCase();
      } else if (/[A-Z]/.test(item)) {
        modified = ` ${item.toLowerCase()}`;
      }
      return modified;
    })
    .join('');
  return cased_title;
}


/**
 *
 * @param _path a pathname to process
 * @returns a title-cased version of the path name without "/"
 */
export function getTitleFromPath(_path: string) {
  return getTitleCase(_path.split('/')[0].split('-').join(' '));
}
export function snakeCaseToTitle(_string: string) {
  return getTitleCase(
    _string
      .split('_')
      .map((word) => {
        const splitWord = word.split('');
        splitWord[0] = splitWord[0].toUpperCase();
        return splitWord.join('');
      })
      .join(' ')
  );
}
export function kebabCaseToTitleKebab(_string: string) {
  return getTitleCase(
    _string
      .split('-')
      .map((word) => {
        const splitWord = word.split('');
        splitWord[0] = splitWord[0].toUpperCase();
        return splitWord.join('');
      })
      .join('-')
  );
}

export function emptyStringToNull(_string: string): string | null {
  return _string.length < 1 ? null : _string;
}


export function isEmptyString(string: string) {
  return typeof string === 'string' && string.length === 0;
}

export function handelLongText(string: string | undefined, limit: number) {
  if (!string) return null;
  if (string.length <= limit) {
    return string;
  } else {
    return `${string.substring(0,limit)}...`
  }
  
}
