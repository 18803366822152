import styles from '../EmptyLeaderboard/styles.module.css'

export function EmptyLeaderboard() {
  const main = 'Waiting for you to start an activity...'

  return (
    <div className={styles.errorsHazzardParent}>
      <div className={styles.frameWrapper}>
        <i className={styles.boldText}>
          <p>{main}</p>
        </i>
      </div>
    </div>
    );
}
