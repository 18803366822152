
import { createRoot } from "react-dom/client";

import "./index.scss";

import { App } from "./App";

// setup for hot reload in dev
if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept();
}

// create a root element and append to document.body
const container = document.querySelector("#root") as HTMLElement;

// create app root in container element
const root = createRoot(container);

// render app in element
root.render(<App />);
